import { useContext } from "react";
import useSWR from "swr";
import ApplicationContext from "../Context";
import type { OutletResponse } from "../types/outlet";
import { fetcherS3 } from "./fetcherS3";
import { FetchResult } from "../types/fetcher";

export default function useOutlet(outlet_code: string) {
  const { code } = useContext(ApplicationContext);

  const {
    data: outlet,
    error: outletError,
    isLoading: outletLoading,
    mutate: mutatingOutlet,
  } = useSWR<FetchResult<OutletResponse>, Error>(
    `${outlet_code}`,
    () =>
      fetcherS3<OutletResponse>(code, [
        { variableName: "hs", alias: "available_hs" },
        { variableName: "services", alias: "product_services" },
        { variableName: "products", alias: "products" },
        { variableName: "home", alias: "home" },
        { variableName: "queue", alias: "queue" },
      ]),
    { refreshInterval: 20000, refreshWhenHidden: false }
  );

  return {
    outlet: {
      code: outlet?.result?.home?.outlet?.outlet_code || [],
      hairstylists:
        outlet?.result.available_hs?.map((hairstylist) => ({
          id: hairstylist.id_user_hair_stylist,
          name: hairstylist.name,
          nickname: hairstylist.nickname,
          shift_time: hairstylist.shift_time,
          photo_url: hairstylist.photo,
          status: hairstylist.available_status,
          current_service: hairstylist.current_service,
          end_shift: hairstylist.end_shift,
          order: hairstylist.order,
        })) || [],
      id: outlet?.result?.home?.outlet.id_outlet,
      isError: outletError,
      isLoading: outletLoading,
      name: outlet?.result?.home?.outlet.outlet_name,
      products:
        outlet?.result.products?.map((product) => ({
          code: product.product_code,
          description: product.product_description,
          id: product.id_product,
          id_brand: product.id_brand,
          name: product.product_name,
          photo_url: product.photo,
          price: product.product_price,
          status: product.product_stock_status,
          stock: product.qty_stock,
          type: product.product_type,
        })) || [],
      services:
        outlet?.result.product_services?.map((service) => ({
          code: service.product_code,
          description: service.product_description,
          id_brand: service.id_brand,
          id: service.id_product,
          name: service.product_name,
          photo_url: service.photo,
          price: service.product_price,
          status: service.product_stock_status,
          type: service.product_type,
        })) || [],
    },
    queues: {
      currents: outlet?.result?.queue?.current_cust || [],
      isError: outletError,
      isLoading: outletLoading,
      orders: outlet?.result?.queue?.order_list || [],
      waitings: outlet?.result?.queue?.waiting || [],
    },
    mutatingOutlet,
  };
}
