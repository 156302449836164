import { FetchRequest, FetchResult } from "../types/fetcher";

export const fetcherS3 = async <T>(outletCode: string, requests: FetchRequest[]): Promise<FetchResult<T>> => {
  if (!Array.isArray(requests)) {
    throw new Error("Input must be an array of objects.");
  }

  const fetchPromises = requests.map(({ variableName, alias }) => {
    const fullUrl = `${process.env.REACT_APP_AWS_URL}/POS/${outletCode}/main/${variableName}.json?_=${Date.now()}`;

    return fetch(fullUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (!data || !data.result) {
          throw new Error(`Response does not contain a 'result' property.`);
        }
        return { key: alias, result: data.result };
      })
      .catch((error) => {
        console.error(`Error fetching ${fullUrl}:`, error);
        return { key: alias, result: null };
      });
  });

  const results = await Promise.all(fetchPromises);
  const resultObject: FetchResult<T> = { result: {} as T };

  results.forEach(({ key, result }) => {
    if ((resultObject.result as any).hasOwnProperty(key)) {
      (resultObject.result as any)[key] = result;
    } else {
      (resultObject.result as any)[key] = result;
    }
  });

  return resultObject;
};
