import { useContext } from "react";
import ApplicationContext from "../Context";
import type { ListTransactionProductServiceProps, ListTransactionServiceProps } from "../types/history-transaction";
import useSWR from "swr";
import { FetchResult } from "../types/fetcher";
import { fetcherS3 } from "./fetcherS3";

export default function useHistoryTransaction(outlet_code: string) {
  const { code } = useContext(ApplicationContext);

  const {
    data: service,
    error: serviceError,
    isLoading: serviceLoading,
    mutate: mutatingHistory,
  } = useSWR<FetchResult<{ list_services: ListTransactionServiceProps[]; list_products: ListTransactionProductServiceProps[] }>, Error>(
    [`${outlet_code}`, "list"],
    () =>
      fetcherS3<{ list_services: ListTransactionServiceProps[]; list_products: ListTransactionProductServiceProps[] }>(code, [
        { variableName: "list-trx-services", alias: "list_services" },
        { variableName: "list-trx-products", alias: "list_products" },
      ]),
    { refreshInterval: 20000, refreshWhenHidden: false }
  );

  const servicesData = service?.result.list_services;
  const servicesEmpty = service?.result.list_services?.length === 0;

  const productsWithServicesData = service?.result.list_products;
  const productsWithServicesEmpty = service?.result.list_products?.length === 0;

  return {
    services: {
      data: servicesData || [],
      isEmpty: servicesEmpty,
      isError: serviceError,
      isLoading: serviceLoading,
    },
    productsWithServices: {
      data: productsWithServicesData || [],
      isEmpty: productsWithServicesEmpty,
      isError: serviceError,
      isLoading: serviceLoading,
    },
    mutatingHistory,
  };
}
